<template>
    <div class="portfolioSection">
        <h2 class="pageTitle">Portfolio</h2>
        <div class="filter" style="padding-bottom: 20px;">
            <h3 id="listBtn" class="filterButton extendedButton" v-on:click="filterSelection('list')">Click here to view an extended list as text</h3>
        </div>
        <div class="filter">
            <h3 id="allBtn" class="filterButton" v-on:click="filterSelection('all')">All</h3>
            <h3 id="projectsBtn" class="filterButton" v-on:click="filterSelection('projects')">Digital Publications</h3>
            <h3 id="gamesBtn" class="filterButton" v-on:click="filterSelection('games')">Games & Interactive Media</h3>
            <h3 id="elitBtn" class="filterButton" v-on:click="filterSelection('elit')">Electronic Lit. & Computational Arts</h3>
			<h3 id="curationsBtn" class="filterButton" v-on:click="filterSelection('curations')">Curation & Publishing</h3>
			<h3 id="arvrBtn" class="filterButton" v-on:click="filterSelection('arvr')">AR, VR, XR</h3>
            <h3 id="webBtn" class="filterButton" v-on:click="filterSelection('web')">Web Design</h3>
	
<!--			<router-link :to="{ name: 'ProjectList'}" class="filterContainer">-->
<!--                <h3 class="filterButton">Extended Project List</h3>-->
<!--            </router-link>-->
        </div>
        <div class="grid">
            <div class="project projectFilter show" v-for="project in projects" :key="project.id"
                 :class="project.filter, project.slug">
                <div class="projectWidthControl">
                    <router-link :to="{ name: 'Project', params: { project_slug: project.slug } }">
                        <div class="top">
                            <div class="buttons">
                            </div>
                            <div class="liveContainer">
                                <div class="liveIndicator">
                                    <p class="liveText cartridgeText" :class="project.slug" v-html="project.status"></p>
                                    <span class="liveBtn" :class="project.statusClass">-</span>
                                </div>
                            </div>
                        </div>
                        <div class="display" :class="project.slug">
                            <img :src="project.featuredimage" class="displayImg" alt="">
                            <div class="displayText">
                                <h2 class="title" v-html="project.title"></h2>
                            </div>
                        </div>
                        <div class="bottomContainer">
                            <div class="buttonContainer">
                                <div class="gamepadContainer">
                                    <i class="material-icons gamepad" :class="project.slug">games</i>
                                </div>
                                <div class="smallBtn">
                                    <i class="material-icons displayBtn">favorite_border</i>
                                </div>
                                <div class="button">
                                    <span class="openBtn">Start</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
		<ProjectList class=" listProjects projectFilter show list" />
	</div>
</template>

<script>
    import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
    import firebaseApp from "@/firebase";

    const db = getFirestore(firebaseApp);

    import {wrapGrid} from 'animate-css-grid';
	import ProjectList from "@/pages/ProjectList";

    export default {
        name: "Portfolio2",
		components: {ProjectList},
		data() {
            return {
                projects: []
            }
        },
        methods: {
            filterSelection(c) {
                let x, i;
                x = document.getElementsByClassName("projectFilter");

                const allBtn = document.getElementById('allBtn')
                const projectsBtn = document.getElementById('projectsBtn')
                const gamesBtn = document.getElementById('gamesBtn')
                const elitBtn = document.getElementById('elitBtn')
				const curationsBtn = document.getElementById('curationsBtn')
				const arvrBtn = document.getElementById('arvrBtn')
                const webBtn = document.getElementById('webBtn')
				const listBtn = document.getElementById('listBtn')

                if (c === 'all') {
                    allBtn.classList.add('activeBtn')
                    projectsBtn.classList.remove('activeBtn')
                    gamesBtn.classList.remove('activeBtn')
                    elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
                    webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'games') {
                    allBtn.classList.remove('activeBtn')
                    projectsBtn.classList.remove('activeBtn')
                    gamesBtn.classList.add('activeBtn')
                    elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
                    webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'arvr') {
                    allBtn.classList.remove('activeBtn')
                    projectsBtn.classList.remove('activeBtn')
                    gamesBtn.classList.remove('activeBtn')
                    elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.add('activeBtn')
                    webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'projects') {
                    allBtn.classList.remove('activeBtn')
                    projectsBtn.classList.add('activeBtn')
                    gamesBtn.classList.remove('activeBtn')
                    elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
                    webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'web') {
                    allBtn.classList.remove('activeBtn')
                    projectsBtn.classList.remove('activeBtn')
                    gamesBtn.classList.remove('activeBtn')
                    elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
                    webBtn.classList.add('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'elit') {
                    allBtn.classList.remove('activeBtn')
                    projectsBtn.classList.remove('activeBtn')
                    gamesBtn.classList.remove('activeBtn')
                    elitBtn.classList.add('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
                    webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'curations') {
					allBtn.classList.remove('activeBtn')
					projectsBtn.classList.remove('activeBtn')
					gamesBtn.classList.remove('activeBtn')
					elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.add('activeBtn')
					arvrBtn.classList.remove('activeBtn')
					webBtn.classList.remove('activeBtn')
					listBtn.classList.remove('activeBtn')
				} else if (c === 'list') {
					allBtn.classList.remove('activeBtn')
					projectsBtn.classList.remove('activeBtn')
					gamesBtn.classList.remove('activeBtn')
					elitBtn.classList.remove('activeBtn')
					curationsBtn.classList.remove('activeBtn')
					arvrBtn.classList.remove('activeBtn')
					webBtn.classList.remove('activeBtn')
					listBtn.classList.add('activeBtn')
				}
				
                if (c === "all") c = "";

                for (i = 0; i < x.length; i++) {
                    this.removeClass(x[i], "show");
                    if (x[i].className.indexOf(c) > -1) {
                        this.addClass(x[i], "show");
                    }
                }
				const plistID = document.getElementById("projectList")
				if (c === "") {
					plistID.classList.remove("show")
					console.log('removed')
				}
			},
            addClass(element, name) {
                let i, arr1, arr2;
                arr1 = element.className.split(" ");
                arr2 = name.split(" ");
                for (i = 0; i < arr2.length; i++) {
                    if (arr1.indexOf(arr2[i]) === -1) {
                        element.className += " " + arr2[i];
                    }
                }
            },
            removeClass(element, name) {
                let i, arr1, arr2;
                arr1 = element.className.split(" ");
                arr2 = name.split(" ");
                for (i = 0; i < arr2.length; i++) {
                    while (arr1.indexOf(arr2[i]) > -1) {
                        arr1.splice(arr1.indexOf(arr2[i]), 1);
                    }
                }
                element.className = arr1.join(" ");
            }
        },
        async created() {
            const q = query(collection(db, "portfolio"), where("show", "==", true), where("major", "==", true));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {
                let project = doc.data();
                project.id = doc.id;
                this.projects.push(project);
            })
            this.projects = this.projects.sort((a, b) => {
                let fa = a.title.toLowerCase(),
                    fb = b.title.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        },
        mounted() {
            this.filterSelection('all')

            const grid = document.querySelector(".grid");
            wrapGrid(grid, {duration: 400, stagger: 75})
        }
    }
</script>

<style scoped lang="scss">
    .projects {
        margin: 0 auto;
    }

    a {
        text-decoration: none;
    }
    .pageTitle {
        margin-bottom: 12px;
    }

    .portfolioSection {
        width: 95%;
        max-width: 1400px;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .filter {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
		grid-gap: 16px;
        @media only screen and (max-width: 800px) {
            //display: grid;
            //grid-template-columns: 1fr 1fr;
            justify-items: left;
			grid-gap: 12px;
		}

        .filterContainer {
            @media only screen and (max-width: 800px) {
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: 2px solid #534598;
            }
        }

        .filterButton {
            cursor: pointer;
            font-weight: normal;
            font-size: 20px;
            color: white;
            margin: 0;
            padding: 6px 8px;
			background-color: #393939;
            border: 2px solid #534598;

            &:hover {
                border: 2px solid rgba(217, 217, 217, 0.7);
                transition: .5s;
            }

            @media only screen and (max-width: 800px) {
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                padding: 6px 8px;
                font-size: 18px;
            }
        }
        
        .extendedButton {
            background-color: rgba(188, 119, 58, 0.80);
            border-radius: 18px;
            padding: 4px 16px;
        }

        .activeBtn {
            color: black;
			background-color: yellow;
            border: 2px solid #534598;
			transition: 0.5s;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 80px 40px;
        padding-top: 20px;
        justify-items: center;
        @media only screen and (max-width: 1350px) {
            grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width: 800px) {
            grid-template-columns: 1fr;
        }
    }
	
    .project.show {
        display: flex;
    }
	
	.listProjects {
		display: none;
		&.show {
			display: block;
		}
	}
	

    .project {
        flex-direction: column;
        padding: 10px 20px 10px 20px;
        max-width: 360px;
        min-height: 500px;
        margin-left: 0;
        margin-right: 0;
        display: none;
        background-color: #b9b4ff;
        border-radius: 12px;
        box-shadow: -10px 15px 35px rgba(0, 0, 0, 0.1),
        8px 8px 20px rgba(0, 0, 0, 0.25),
        24px 15px 70px rgba(0, 0, 0, 0.2),
        inset 10px -15px 40px rgba(0, 0, 0, 0.15),
        inset -10px 15px 40px rgba(0, 0, 0, 0.05);

        &.afrofuturefemme {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=gLcyygzGE2Qr8ODPHR9TdldKHpQJjdS9");
            background-size: cover;
            background-color: #221741;
        }

        &.amherstuprising {
            background-color: #373737;
        }

        &.collapse {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=VsSUNtd3W2qZ9xgDmpbmPSHKk31450i9");
            background-size: cover;
            background-color: dimgrey;
        }

        &.crementum {
            background-color: #94B066;
        }

        &.energy {
            background-color: #b0f5bf;
        }

        &.saltcodes {
            background-color: #665C9F;
        }

        &.literarydj {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=cZAnYLEJVMsbboPcUnKfy4QXnEZ1vvry");
            background-size: cover;
        }

        &.solidaritybook {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=b0r6W6d40j4aRKBMN4Qi6TLg0S6gzqiq");
            background-size: cover;
            //background-color: #988FBB;
        }

        &.umugensite {
            //background-image: url("https://awikhigan.irlh.org/gen/wl/?id=5CXgwO2pdIdwvarfmRweMErkRko9pICm");
            //background-size: cover;
            background-color: #070707;
        }

        &.whatsinyourair {
            background-color: #b5eee5;
        }

        &.antitemporalletters {
            background-color: #2e2051;
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=9VP9WvAcSQ07s86Rrgmf6dJKVox2lTXT");
            background-size: cover;

        }
		
		&.materialconditions {
			background-color: #8AC6D3;
		}
    }

    .projectList {
        color: #82ff7e;

        &:hover {
            text-decoration: underline;
        }
    }

    .projectGrid {
        object-fit: cover;
        margin: 0 auto;
        border-radius: 8px;
        border: 8px black solid;
    }

    .title {
        font-family: 'Bitbybit', monospace;
        font-weight: 400;
        color: #000000;
        font-size: 28px;
        // text-align: center;
        max-width: 360px;
        margin: 0 auto 0 auto;
    }

    .display {
        height: 275px;
        // width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 12px;
        border: 12px #282828 solid;

        &.afrofuturefemme, &.amherstuprising, &.literarydj, &.crementum, &.umugensite, &.antitemporalletters {
            border: 12px #6d6d6d solid;
        }

        .displayImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        .displayText {
            background-color: #e9e9e9;
            margin-top: -5px;
            // height: 100%;
            padding: 8px;
            border-top: 4px black solid;
        }
    }

    .top {
        margin: 0 8px;
        // padding-top: 12px;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    .liveContainer {
        display: flex;
        justify-content: flex-end;
    }

    .liveIndicator {
        display: flex;
        flex-direction: row;
        align-items: center;
        // background-color: #e3e3e3;
        padding: 4px;
        border-radius: 4px;

        .liveBtn {
            background-color: #d49696;
            border-radius: 40px;
            color: rgba(255, 76, 76, 0);
            width: 30px;
            height: 8px;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.671733);
            text-align: right;
        }

        .live {
            background-color: #ff4c4c;
            box-shadow: 0 0 25px #ff4c4c, 0 0 10px #ff4c4c, 1px 1px 1px rgba(0, 0, 0, 0.671733);
        }

        .liveText {
            margin: 4px auto 0 auto;
            font-size: 14px;
            text-align: right;
            padding-right: 8px;

        }
    }

    .cartridgeText {
        color: #1a1a1a;

        &.afrofuturefemme, &.amherstuprising, &.saltcodes, &.literarydj, &.solidaritybook, &.crementum, &.umugensite {
            color: #f8f8f8;
        }
    }

    .bottomContainer {
        margin: 12px;

        .projDescription {
            margin: 12px 0 0 0;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        padding: 30px 0;
    }

    .gamepadContainer {
        .gamepad {
            font-size: 110px;
            color: #454545;
            margin-right: 100px;
            width: 100%;

            &.afrofuturefemme, &.amherstuprising, &.saltcodes, &.literarydj, &.solidaritybook, &.crementum, &.umugensite {
                color: #d3d3d3;
            }

            @media only screen and (max-width: 800px) {
                margin-right: 50px;
            }
        }
    }

    .button {
        font-family: 'Bitbybit', monospace;

        height: 70px;
        width: 70px;
        margin-top: 15px;
        border-radius: 100px;
        background-color: #01AEAA;
        // background-color: #454545;

        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.671733),
        inset 5px -5px 5px rgba(0, 0, 0, 0.1),
        inset -5px 5px 5px rgba(0, 0, 0, 0.05);

        .openBtn {
            // color: #eaeaea;
            color: #eaeaea;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;
            width: 70px;
        }
    }

    .smallBtn {
        height: 45px;
        width: 45px;
        margin-top: 65px;
        margin-right: 10px;
        border-radius: 100px;
        background-color: #ea6666;
        // background-color: #454545;

        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.671733),
        inset 5px -5px 5px rgba(0, 0, 0, 0.1),
        inset -5px 5px 5px rgba(0, 0, 0, 0.05);

        .displayBtn {
            // color: #242424;
            color: #eaeaea;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 45px;
        }
    }

    .projectWidthControl {

        @media only screen and (max-width: 800px) {
            width: 320px;
        }
    }
</style>