<template>
    <a v-if="myproject.url" :href="myproject.url" target="_blank">
        <div class="grid">
            <div>
                <p class="title">{{ myproject.title }}</p>
                <p class="role" v-html="myproject.citation"></p>
                <p class="description" v-html="myproject.shortDescription"></p>
                <p class="role" style="color: #ccc; font-size: 16px;">> {{myproject.role}} </p>
            </div>
            <p class="year">{{ myproject.year }}</p>
            <p class="hideURL">
            <span v-if="myproject.url"><i
                class="large material-icons url">link</i></span>
                <span class="url urlMobile" v-if="!myproject.url">N/A</span>
            </p>
        </div>
    </a>
        <div v-if="!myproject.url" class="grid">
            <div>
                <p class="title">{{ myproject.title }}</p>
                <p class="role" v-html="myproject.citation"></p>
                <p class="description" v-html="myproject.shortDescription"></p>
                <p class="role" style="color: #ccc; font-size: 16px;">> {{myproject.role}} </p>
            </div>
            <p class="year">{{ myproject.year }}</p>
            <p class="hideURL">
            <span v-if="myproject.url"><i
                class="large material-icons url">link</i></span>
                <span class="url urlMobile" v-if="!myproject.url">N/A</span>
            </p>
        </div>

</template>

<script>
    export default {
        name: "ListedProject",
        props: ['myproject']
    }
</script>

<style scoped lang="scss">
    p {
        margin: 0;
    }
    
    a {
        color: white;
        text-decoration: none;
        
        &:hover {
            color: yellow;
        }
    }
    
    .grid {
        max-width: 100%;
        word-wrap: break-word;
        display: grid;
        grid-template-columns: 10fr 1fr 1fr;
        grid-gap: 40px;
        padding-bottom: 20px;
        
        @media only screen and (max-width: 800px) {
            display: block;
            padding-bottom: 20px;
        }
        
        //&:hover {
        //    color: #c8ffe4;
        //}
        //
        //&:hover .url {
        //    color: #c8ffe4;
        //}
    }
    
    .title {
        font-size: 20px;
        font-weight: bold;
        color: yellow;
        margin-bottom: 2px;
        @media only screen and (max-width: 1140px) {
            font-size: 18px;
        }
    }
    
    .role {
        font-size: 20px;
        font-family: "Times New Roman", serif;
        @media only screen and (max-width: 1140px) {
            font-size: 18px;
        }
    }
    
    .description {
        font-size: 20px;
        color: #ccc;
        font-family: "Times New Roman", serif;
        //border-left: 5px solid white;
        //padding-left: 10px;
        //margin-left: 4px;
        @media only screen and (max-width: 1140px) {
            font-size: 16px;
        }
    }
    
    .year {
        @media only screen and (max-width: 1140px) {
            color: #e0e0e0;
            display: none;
        }
    }
    
    .url {
        transition: .25s;
        
        &:hover {
            color: yellow;
            transform: rotate(90deg);
            transition: .25s;
        }
        @media only screen and (max-width: 1140px) {
            font-size: 16px;
            color: #ccc;
        }
    }
    
    .urlMobile {
        @media only screen and (max-width: 1140px) {
            font-size: 16px;
            color: #ccc;
        }
    }
</style>