<template>
    <Nav/>
    <router-view/>
</template>

<script>
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";

    export default {
        components: {Footer, Nav}
    }
</script>

<style lang="scss">
    @font-face {
        font-family: Bitbybit;
        src: url('assets/bitbybit.woff');
    }

	@font-face {
		font-family: Roboto Mono;
		src: url('assets/RobotoMono-Regular.ttf');
		font-weight: normal;
	}
	@font-face {
		font-family: Roboto Mono;
		src: url('assets/RobotoMono-Bold.ttf');
		font-weight: bold;
	}

    html {
        scroll-behavior: smooth;
        background-color: #534598;
    }

    h1 {

    }

    h2 {
        font-size: 36px;
        text-align: left;
    }

    h3 {

    }

    p {
        font-size: 20px;
    }

    #app {
        font-family: Roboto Mono, monospace;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #fff;
    }

    .description {
        a {
            color: #c4c4c4;
            text-decoration: underline;

            &:hover {
                color: #9be9c9;
            }
        }
    }
</style>