<template>
    <div class="about">
		<div class="cols">
			<div class="col2">
				<h1>Bio</h1>
				<p><span class="name">Andrew W. Smith</span>  is a developer, designer, researcher, and working artist whose projects blend immersive and interactive technologies with themes such as memory, environmental futures, Black life, and virtual worlds. His expertise spans analog and digital game design, archival and interpretive web development, augmented reality (AR), and virtual reality (VR). </p>
				<p>Smith’s innovative work has earned him residencies and fellowships with Snap Inc. (Snapchat), the University of Maryland College Park, and Amherst College. Currently, he is a Visiting Assistant Research Professor at the University of Maryland College Park, positioned in African American Digital and Experimental Humanities (AADHum) and the Maryland Institute for Technology in the Humanities (MITH). </p>
                <p>His work has been featured in publications such as <i>Urgency Reader 2</i>, <i>The New River</i>, <i>salt.codes</i>, <i>Voidspace Interactive Arts</i>, and <i>SUPERJUMP</i>. Smith is an avid collaborator, having worked with textile and mixed-media artist Sonya Clark on the <i>Solidarity Book Project</i>, with researcher and artist Allie Martin on <i>Black Covid Care</i>, and with Project STAND on several initiatives to document and archive student activism. </p>
                <p>His current projects include <i>LiteraryDJ</i>, an interactive arts installation supported by a Quantum Computing x ArtsAmp Grant that exploring computational poetry, natural language processing, African American literature, and textual remixing, as well as <i>Energy</i>, an educational board game being designed and published through his studio, Varda Studios, LLC., that focuses on climate change, natural resource management, and environmental futures. </p>
  			</div>
            <div class="profileImage">

            </div>
        </div>
	</div>
</template>

<script>
    export default {
        name: "Bio"
    }
</script>

<style scoped lang="scss">

	.about {
		width: 95%;
		max-width: 1140px;
        margin: 0 auto;
        padding-bottom: 40px;
		text-align: left;
	}
    .footerVid {
        width: 1140px;
        margin: 0 auto;
    }

	p {
		text-align: left;
        font-family: "Times New Roman", serif;
		font-size: 22px;
		line-height: 1.25;
				@media only screen and (max-width: 800px) {
					font-size: 20px;
		}
	}

    .name {
        color: yellow;
    }

    .profileImage {
        height: 400px;
        width: 400px;
		max-width: 95%;
        background-image: url("https://awikhigan.irlh.org/gen/wl/?id=0s0RVdHWvW1dU4cajFINZ8xnbtJLML44");
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        transition: 1s;
        &:hover {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=yOgbF4nyxKGCKlWDk1wllH1u2xd3ei1l");
            transition: 1s;
        }
    }

</style>