import {createRouter, createWebHistory} from 'vue-router'

import Bio from "../pages/Bio";
import PortFilter from "../pages/PortFilter";
import Project from "../pages/Project";
import ProjectList from "../pages/ProjectList";
import WritingSpeaking from "../pages/WritingSpeaking";

const routes = [
    {
        path: '/',
        name: 'Portfolio',
        component: PortFilter
    },
    {
        path: '/bio',
        name: 'Bio',
        component: Bio
    },
    {
        path: '/portfolio/:project_slug',
        name: 'Project',
        component: Project
    },
    {
        path: '/portfolio/list',
        name: 'ProjectList',
        component: ProjectList
    },
    {
        path: '/writing-speaking',
        name: 'WritingSpeaking',
        component: WritingSpeaking
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
