<template>
    <div id="project" v-if="project">
        <div class="columns">
            <div class="row aboutPanel">
                <div class="descriptionContainer">
                    <h2>{{ project.title }}</h2>
                    <p class="description" v-html="project.description"></p>
                    <div class="modules" v-if="project.modules" v-html="project.modules"></div>
                    <img :src="project.featuredimage" class="featuredImg" alt="" v-if="!project.modules">
                </div>
            </div>
            <div class="row infoPanel">
                <p class="role"><span class="category">Role: </span>{{ project.role }}</p>
				<p class="collaborators"><span class="category">Collaborators: </span>{{ project.collaborators }}</p>
                <p class="year"><span class="category">Initial Release Year: </span>{{project.year}}</p>
                <p class="URL" v-if="project.url"><span class="category">Link: </span><a :href="project.url" target="_blank">{{project.url}}</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
    import firebaseApp from "@/firebase";

    const db = getFirestore(firebaseApp);

    export default {
        name: "Project",
        data() {
            return {
                project: null,
            }
        },
        async created() {
            const q = query(collection(db, "portfolio"), where("slug", "==", this.$route.params.project_slug));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {
                let projectRef = doc.data();
                projectRef.id = doc.id;
                this.project = (projectRef);
            })
        }
    }
</script>

<style scoped lang="scss">
    #project {
        max-width: 1400px;
        width: 95%;
        margin: 0 auto;
        padding-top: 40px;
    }
    h2 {
        font-size: 42px;
        margin: 8px 0;
    }
    h3 {
        margin: 8px 0;
        font-size: 32px;
        font-weight: normal;
    }
    a {
        color: white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .columns {
        display: flex;
        flex-direction: row;

        .row {
            display: flex;
            flex-direction: column;
        }

        .aboutPanel {
            flex-basis: 75%
        }

        .infoPanel {
            flex-basis: 25%;
        }

        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;
        }
    }

    .featuredImg {
        width: 100%;
        height: 350px;
        object-fit: contain;
        padding-top: 20px;
    }
    .role, .collaborators, .year, .url {
        font-size: 20px;
    }
    .category {
        color: yellow;
    }

    .description {
        font-size: 20px;
    }

    .infoPanel {
        max-width: 400px;
        background-color: #655f86;
        padding: 20px;
        margin: 40px 20px;
        border-radius: 12px;
        box-shadow: -5px 5px 25px rgba(0, 0, 0, 0.05),
        8px 8px 15px rgba(0, 0, 0, 0.15),
        12px 7px 40px rgba(0, 0, 0, 0.2);
        @media screen and (max-width: 1024px) {
			max-width: 95%;
            margin: 0;
        }

        .URL {
            word-wrap: break-word;
        }
    }

    .modules {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
        padding: 0 80px;
    }
</style>