<template>
	<div class="footerVid">
		<video class="footerVid" src="https://awikhigan.irlh.org/gen/wl/?id=7e3f4VkKiJnwvQvHhqYXKf5UeCVNjM8i" autoplay playsinline muted loop width="1140px"></video>
	</div>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style scoped>
	.footerVid {
		width: 1400px;
		margin: 0 auto;
	}
</style>