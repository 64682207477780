<template>
    <div id="writingspeaking">
        <h2>Essays & Other Writing</h2>
        <div class="text" v-for="text in writing" :key="writing.id">
            <p class="title">
                <span class="titleText">{{ text.title }}</span>
            </p>
            <p class=" title subText">
                <span class="forceWrap">{{ text.venue }} | {{ text.when }}</span>
                <span v-if="text.url"><a :href="text.url" target="_blank"><i
                    class="large material-icons url">link</i></a></span>
            </p>
        </div>
        <h2>Recent Speaking Engagements & Workshops</h2>
        <div class="text" v-for="text in talks" :key="writing.id">
            <p class="title">
                <span class="titleText">{{ text.title }}</span>
            </p>
            <p class="title subText">
                <span class="forceWrap">{{ text.venue }} | {{ text.when }}</span>
                <span v-if="text.url"><a :href="text.url" target="_blank"><i
                    class="large material-icons url">link</i></a></span>
            </p>
            <p class="reason">[ {{ text.reason }} ]</p>
        </div>
    </div>
</template>

<script>
    import {collection, getDocs, query, where, getFirestore} from "firebase/firestore";
    import firebaseApp from "@/firebase";
    
    const db = getFirestore(firebaseApp);
    
    export default {
        name: "WritingSpeaking",
        data() {
            return {
                writing: [],
                talks: []
            }
        },
        async created() {
            const q = query(collection(db, "writingtalks"), where("show", "==", true));
            const querySnapshot = await getDocs(q)
            
            querySnapshot.forEach((doc) => {
                let text = doc.data();
                text.id = doc.id;
                if (text.category === 'Talk' || text.category === 'Workshop') {
                    this.talks.push(text);
                } else {
                    this.writing.push(text);
                }
            })
            this.talks = this.talks.sort((a, b) => {
                let fa = a.date,
                    fb = b.date;
                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            })
            this.writing = this.writing.sort((a, b) => {
                let fa = a.date,
                    fb = b.date;
                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            })
        }
    }
</script>

<style scoped lang="scss">
    
    h2 {
        font-size: 32px;
    }
    
    a {
        color: #efef85;
        text-decoration: none;
    }
    
    p, span {
        max-width: 100%
    }
    
    #writingspeaking {
        width: 95%;
        max-width: 1400px;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    
    .text {
        padding-bottom: 20px;
        
        p {
            margin: 4px 0;
        }
    }
    
    .title {
        display: flex;
        flex-wrap: wrap;
        white-space: pre;
        align-items: center;
        
        &.subText {
            font-size: 18px;
            color: #ccc;
        }
    }
    
    .reason {
        font-size: 14px;
        color: #ccc;
    }
    
    .titleText {
        font-size: 20px;
        color: yellow;
        white-space: normal;
        
    }
    
    .info {
        font-size: 18px;
        color: #cbcbcb;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-items: left;
        align-content: center;
    }
    
    .url {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        margin-left: 16px;
        transition: .25s;
        
        &:hover {
            color: yellow;
            transform: rotate(90deg);
            transition: .25s;
        }
    }
    
    .position {
        white-space: pre;
    }
    
    .category {
        font-size: 16px;
        padding: 4px 8px;
        border-radius: 12px;
        color: white;
        
        &.Article {
            background-color: rgba(198, 59, 196, 0.68);
        }
        
        &.Talk {
            background-color: green;
        }
        
        &.Interview {
            background-color: #009987;
        }
        
        &.Workshop {
            background-color: #bc5800;
        }
    }
    
    .forceWrap {
        display: flex;
        flex-wrap: wrap;
        white-space: normal;
    }
</style>