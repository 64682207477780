<template>
    <div class="nav">
        <div class="navItems">
            <div class="name">
                <div class="socialmedia">
                    <a href="https://twitter.com/awwsmith_" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=euaEy0WeKJ0OBk6BbMEhF7XtEzC63ham"
                             alt="twitter link" class="smicons">
                    </a>
                    <a href="https://instagram.com/awwsmith" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=KZM3sXQck2IQboR37Hu5pZDm19sPuVeC"
                             alt="instagram link" class="smicons"></a>
                    <a href="https://github.com/awwsmith" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=AkCjj2zdjeZw3QP4I5DN4T09RVCPriGy"
                             alt="github link" class="smicons"></a>
                    <a href="https://lensstudio.snapchat.com/creator/6MGzWvRM6DxPWZmxHecSWQ" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=MDjue8DywFL8YN87nn6qxDkWgUycsIai"
                             alt="snapchat link" class="smicons">
                    </a>
                    <a href="https://awwsmith.medium.com/" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=dQocb64ACNU0lOVm5wFterjaCQCzVXQL"
                             alt="medium link" class="smicons">
                    </a>
                </div>
				<h2>Andrew W. Smith</h2>
				<p>designer | developer | writer</p>

			</div>

            <div class="navContainer">
                <router-link :to="{ name: 'Portfolio'}">
                    <h3>Portfolio</h3>
                </router-link>
                <router-link :to="{ name: 'WritingSpeaking'}">
                    <h3>Writing/Speaking</h3>
                </router-link>
                <router-link :to="{ name: 'Bio'}">
                    <h3>Bio</h3>
                </router-link>
<!--				<h3>Small Things</h3>-->
            </div>
        </div>
        <div class="headerImg"></div>
    </div>
</template>

<script>
    export default {
        name: "Nav"
    }
</script>

<style scoped lang="scss">
    a {
        color: #fff;
        text-decoration: none;
    }

    h3 {
        margin-bottom: 0;
    }

    .nav {
        max-width: 1400px;
        width: 95%;
        margin: 0 auto;
    }

    h2 {
        width: 100%;
    }

    .navItems {
        max-width: 1400px;
        width: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        align-items: flex-end;
        padding: 12px 0;
        @media only screen and (max-width: 800px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        h3 {
            font-size: 22px;
            font-weight: normal;
            @media only screen and (max-width: 800px) {
                font-size: 22px;
                margin: 0 auto;
            }
			&:hover {
				color: yellow;
			}
        }

        .navContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
			grid-gap: 40px;

            a {
                align-items: flex-end;
            }

            @media only screen and (max-width: 800px) {
                justify-content: space-around;
                align-items: center;
                padding-top: 20px;
				grid-gap: 20px;
                flex-direction: column;
            }

        }
    }

    .name {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media only screen and (max-width: 800px) {
            align-items: center;
        }

        h2 {
            margin: 0;
            font-size: 36px;
            font-weight: normal;
            @media only screen and (max-width: 800px) {
                text-align: center;
            }
        }

        p {
			font-size: 16px;
            margin: 0;
        }
    }

    .socialmedia {
        padding-top: 2px;
		display: flex;
		flex-direction: row;
		grid-gap: 30px;
		a {
			display: flex;
			align-items: center;
		}
    }
	.smicons {
		width: 26px;
		transition: transform 250ms, scale 250ms;
		
		&:hover {
			transform: scale(1.2);
		}
	}
	
    .headerImg {
        max-width: 1400px;
        height: 300px;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        background-image: url("https://awikhigan.irlh.org/gen/wl/?id=oBRO4HrA4drPCk4RwZaWmnNSfAlDWi2k");
        transition: 1s;

        &:hover {
            background-image: url("https://awikhigan.irlh.org/gen/wl/?id=32QSCHnVPZwKQe6joBiMsr8fiB3qN4sg");
            transition: 1s;
        }

        @media only screen and (max-width: 800px) {
            height: 200px;
        }
    }


</style>