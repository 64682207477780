<template>
    <div id="projectList">
<!--        <div class="allProjects">-->
<!--            <p class="header">Title</p>-->
<!--            <p class="header">Initial Release</p>-->
<!--            <p class="header hideURL">Link</p>-->
<!--        </div>-->
        <ListedProject v-for="project in projects" :key="project.id" :myproject="project"/>
    </div>
</template>

<script>
    import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
    import firebaseApp from "@/firebase";
    import ListedProject from "@/components/ListedProject";

    const db = getFirestore(firebaseApp);

    export default {
        name: "ProjectList",
        components: {ListedProject},
        data() {
            return {
                projects: []
            }
        },
        async created() {
            const q = query(collection(db, "portfolio"), where("show", "==", true));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {
                let project = doc.data();
                project.id = doc.id;
                this.projects.push(project);
            })
            this.projects = this.projects.sort((a, b) => {
                let fa = a.year.toLowerCase(),
                    fb = b.year.toLowerCase();
                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            })
        }
    }
</script>

<style scoped lang="scss">
    #projectList {
        max-width: 1400px;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .allProjects {
        display: grid;
        grid-template-columns: 9fr 3fr 1fr;
        grid-gap: 40px;
        padding-bottom: 12px;
        @media only screen and (max-width: 800px) {
			display: block;
        }
    }

    .header {
        font-weight: bold;
        color: yellow;
        @media only screen and (max-width: 1140px) {
            font-size: 16px;
        }
    }

    p {
        margin: 0;
    }

    a {
        color: white;
        text-decoration: none;
        &:hover {
            color: yellow;
            text-decoration: underline;
        }
    }
    .returnContainer {
        display: flex;
        padding: 10px 0 40px 0;
        justify-content:  flex-end;
    }

    .return {
        font-size: 20px;
        white-space: pre;
        display: flex;
        align-items: center;
        border: 1.5px solid yellow;
        padding: 8px 6px;
    }
</style>