import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDJVA1-wSsyGlJfc-24UfT5_hrC1-vnR4w",
    authDomain: "awwsmith-dotcom.firebaseapp.com",
    databaseURL: "https://awwsmith-dotcom.firebaseio.com",
    projectId: "awwsmith-dotcom",
    storageBucket: "awwsmith-dotcom.appspot.com",
    messagingSenderId: "256663797600",
    appId: "1:256663797600:web:46fde4726ca820548d2805",
    measurementId: "G-892FWD927B"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp